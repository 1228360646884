import React from "react";
import Seo from "../components/Seo/seo";
import { graphql, useStaticQuery } from "gatsby";
import AllNews from "../components/News/AllNews";

const NewsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        edges {
          node {
            id
            frontmatter {
              title
              date
              excerpt
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 400
                    blurredOptions: { width: 5 }
                    placeholder: NONE
                  )
                }
              }
            }
            fields {
              slug
            }
            html
            excerpt
          }
        }
      }
    }
  `);

  return (
    <>
      <Seo title="News" description="News for Goriot" />
      <AllNews data={data} />
    </>
  );
};

export default NewsPage;
