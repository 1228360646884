import React from "react";

import * as classes from "./AllNews.module.css";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const AllNews = ({ data }) => {
  return (
    <div className={classes.allNewsContainer}>
      {data.allMarkdownRemark.edges.map((edge) => (
        <div key={edge.node.id}>
          <Link to={`/news/${edge.node.fields.slug}`}>
            <div className={classes.newsItem}>
              <div className={classes.newsThumb}>
                <GatsbyImage
                  image={getImage(edge.node.frontmatter.image)}
                  alt="thumbnail"
                />
              </div>
              <div className={classes.newsText}>
                <small>{edge.node.frontmatter.date}</small>
                <h1>{edge.node.frontmatter.title}</h1>
                <p>{edge.node.frontmatter.excerpt}</p>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default AllNews;
